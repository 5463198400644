

















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Container } from 'inversify';
import moment from 'moment';
import { AuthError } from 'msal';
import { Receipt } from '../../interfaces/api/interface/Receipt';
import { DataOptions } from '../../interfaces/api/interface/DataOptions';
import container from '../../injection/Container';
import { AuthService } from '../../msal/AuthService';
import FormatPrice from '../../functions/FormatPrice';

@Component
export default class Receipts extends Vue {
  private diContainer: Container = container;

  public busAuthService: AuthService;

  private apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.sprkl.app' : process.env.VUE_APP_API_URL;

  dataLoading = false;

  receipts?: Receipt[] = new Array<Receipt>();

  totalRecords = 0;

  tableOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['paymentDate']
  };

  headers = [
    {
      text: 'Name',
      align: 'left',
      sortable: false,
      value: 'name'
    },
    { text: 'Address Line 1', value: 'addressLine1', align: 'start' },
    { text: 'Payment', value: 'pricePaid', align: 'start' },
    { text: 'Date', value: 'paymentDate', align: 'start' }
    // { text: '', value: 'protein' },
    // { text: 'Iron (%)', value: 'iron' }
  ];

  // snackbar
  private snackbar = false;

  private snackbarContent = '';

  private snackbarTimeout = 4000;

  private snackbarColor = 'success';

  constructor() {
    super();
    this.busAuthService = this.diContainer.getNamed<AuthService>('AuthService', 'business');
  }

  async mounted() {
    await this.mountEdit();
  }

  async mountEdit() {
    this.dataLoading = true;
    if (this.$store.state.accessToken === undefined) {
      // alert('No Token');
      try {
        const response = await this.busAuthService.login();
        this.$store.commit('authSuccess', this.busAuthService.getAccount());
        this.$store.commit('accessToken', response.idToken.rawIdToken);

        // console.log(`AccessToken ${JSON.stringify(response.idToken.rawIdToken)}`);
      } catch (e) {
        if (e instanceof AuthError) {
          //   console.log(e);
        } else {
          throw e;
        }
      }
      this.mountEdit();
      //   return;
    }

    await this.fetchReceipts(this.tableOptions.page, this.tableOptions.itemsPerPage);
  }

  async fetchReceipts(page: number, count: number) {
    fetch(`${this.apiUrl}/api/receipts/${page - 1}/${count}`, {
      method: 'get',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$store.state.accessToken}`
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Response Status: ${response.status}\nMessage: ${response.statusText}`);
        }
        return response.json() as Promise<Receipt[]>;
      })
      .then((data: Receipt[]) => {
        this.receipts = data;
        this.totalRecords = data.length;
        this.dataLoading = false;
      })
      .catch(err => {
        this.dataLoading = false;
        this.snackbar = true;
        this.snackbarContent = 'There was an error loading the data please try again.';
        this.snackbarColor = 'error';
      });
  }

  FormatPrice = FormatPrice;

  Moment = moment;

  //   formatPrice(price: number, locale: string, currency: string) {
  //     return this.FormatPrice(price, locale, currency);
  //   }

  @Watch('tableOptions', { deep: true })
  async onPropertyChanged(value: DataOptions, oldValue: DataOptions) {
    if (oldValue.page !== value.page && oldValue.itemsPerPage !== value.itemsPerPage) {
      await this.fetchReceipts(value.page, value.itemsPerPage);
    }
  }
}
