var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex fill-height"},[_c('h2',[_vm._v("Receipts")]),_c('v-row',{attrs:{"lg-12":""}},[_c('v-col',{attrs:{"cols":"8","md":"8"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.receipts,"loading":_vm.dataLoading,"loading-text":"Receipts Loading...","server-items-length":_vm.totalRecords,"options":_vm.tableOptions,"show-expand":"","disable-sort":"","dark":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))])]}},{key:"item.addressLine1",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.addressLine1))])]}},{key:"item.pricePaid",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.FormatPrice(item.pricePaid, undefined, undefined)))])]}},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.Moment.utc(item.paymentDate).format('l LT'))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"lg-12":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(item.name))])]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.addressLine1)),_c('br'),_vm._v(" "+_vm._s(item.addressLine2)),_c('br'),_vm._v(" "+_vm._s(item.addressCity)),_c('br'),_vm._v(" "+_vm._s(item.addressPostCode)),_c('br'),_vm._v(" "+_vm._s(item.country)),_c('br')]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Paid: "+_vm._s(_vm.FormatPrice(item.pricePaid, undefined, undefined))),_c('br'),_vm._v(" Service Charge: "+_vm._s(_vm.FormatPrice(item.serviceCharge, undefined, undefined))),_c('br'),_vm._v(" Transaction Charge: "+_vm._s(_vm.FormatPrice(item.transactionCharge, undefined, undefined))),_c('br'),_vm._v(" Net: "+_vm._s(_vm.FormatPrice(item.netTotal, undefined, undefined))),_c('br')]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Email: "+_vm._s(item.email)),_c('br')])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_vm._v("Right")])],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbarTimeout,"top":"","color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-text',[_vm._v(_vm._s(_vm.snackbarContent))]),_c('v-btn',{attrs:{"color":"white","flat":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }